.App {
  font-family: sans-serif;
  text-align: center;
  background-color: #161d29;
}

.svgWrapper {
  display: flex;
  height: 8vh;
  justify-content: center;
  padding-top: 50px;
}

@media (max-width: 600px) {
  .svgWrapper {
    height: 4vh;
  }
}
